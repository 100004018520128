<template>
  <div class="bannerImgBox">
    <!-- <div ref="gradient" class="gradient" /> -->
    <!-- <div class="MaskLayer" /> -->
    <div class="bannerImg">
      <div class="content">
        <!--项目专业-->
        <!--   -->
        <div class="swipersLefts" @mouseleave="swipersLeftsa()">
          <div class="big">
            <div class="big-top">
              <!-- {{ collageList }} -->
              <div class="ul ellipsis">
                <span v-for="(item, index) in collageList" :key="index"
                  :class="kindIndex && kindIndex.pKind == item.pKind ? 'on' : ''" @mouseenter="swipersLeftsa(item)">{{
                    item.pName }}</span>
                <span class="onhover" @mouseenter="swipersLeftsa()"
                  @click="goNext('/marketing/militarCivilianPersonnel')">军队文职</span>
              </div>
              <!-- <div class="big-people" @click="open">
                <span>高校伙伴</span>
              </div> -->
            </div>
          </div>
          <div v-if="kindIndex" class="big-cent big-centall" @mouseenter="swipersLeftsa(kindIndex)"
            @mouseleave="swipersLeftsa()">
            <div class=" big-cents">
              <div v-if="kindIndex.pStage">
                <template v-for="(stageName, starIndex) in kindIndex.stageZyList">
                  <div v-if="showProject(kindIndex.pKind, stageName.stage)" :key="starIndex" class="title">
                    <div class="kindname" style="margin-right: 15px;" @click="projectDetail(kindIndex)">
                      {{
                        stageName.name == "专升本"
                          ? "自考专升本"
                          : stageName.name
                      }}<i class="el-icon-arrow-right" />
                    </div>
                    <template>
                      <div class="wraps">
                        <div v-for="(itemsss, indexsss) in stageName.zyList" v-show="stageName.name == itemsss.zStage"
                          :key="indexsss" class="wrapss ellipsis" @click.stop="
                            zhuanYeXqs(itemsss.zKind, itemsss.zId, itemsss.pKind)
                            ">
                          {{ itemsss.zName }}
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
              <div v-else>
                <div class="title">
                  <div class="kindname" style="margin-right: 15px;" @click="projectDetail(kindIndex)">
                    {{ kindIndex.pName
                    }}<i class="el-icon-arrow-right" style="margin-left: 5px" />
                  </div>
                  <template>
                    <div v-if="courseClass.length > 0" class="wraps">
                      <div v-for="(zy, zyIndex) in kindIndex.zyList" :key="zyIndex" class="wrapss" @click.stop="
                        zhuanYeXqs(zy.zKind, zy.zId, kindIndex.pKind)
                        ">
                        {{ zy.zName ? zy.zName : "" }}
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="big-hot">
                <div class="colleges_title">
                  <div class="text">
                    热门推荐<i class="el-icon-arrow-right" style="margin-left: 5px" />
                  </div>
                </div>
                <div class="contentWrapLeft">
                  <div v-for="(item, index) in courseClass" :key="index" class="contentItem" @click="goDetails(item)">
                    <div class="imgItem">
                      <el-image :src="item.img" class="imgClass" fit="cover" />
                    </div>
                    <div class="item_content">
                      <div class="titleItem twoEllipsis">{{ item.tiitle }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="imgs" class="big_img">
              <img @click="goNext(imgs.pageUrl)" :src="imgs.imgUrl" alt="">
            </div>
          </div>

        </div>

        <!-- 轮播 -->
        <el-carousel ref="carousel" class="swImg" arrow="never" height="450px" @change="changeItem">
          <el-carousel-item v-for="(item, index) in imgList" :key="index" class="pointer" :autoplay="false">
            <el-image ref="bannerimg" fit="cover" :src="item.img" @click="nav(item)" />
          </el-carousel-item>
        </el-carousel>
        <div class="menu-news" />
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import {
  getSurfaceList,
  getMYTenantKindZyList,
  selectNewPageListToIndex, selectPageListToIndexnew,
  selectKindTenantIdProducts, getMYTenantKindZyListnew,
  getMarketingImgJxuIndex
} from '@/api/home'
import { queryAllArticle } from '@/api/home'
import { getDomain } from '@/api/cookies'
import { Know } from '@/api/know'
const know = new Know()
export default {
  data() {
    return {
      imgList: [],
      newImgList: [],
      kindIndex: null,
      collageList: [],
      newsList: [],
      domain: null,
      active: null,
      search: {},
      index: null,
      gradient: null,
      stageList: [],
      pStageList: [],
      courseClass: [],
      RecommendClassList: [],
      imgs: ''
    }
  },
  created() {
    this.domain = getDomain()
    /* 获取轮播图 */
    getSurfaceList(1, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0) {
        this.imgList = res.msg
      }
    })
    this.loadgetTenantidNavigation() // 调用轮播图导航接口方法
    /* 新闻消息 */
    queryAllArticle('', 4, 1).then((res) => {
      this.newsList = res.rows
    })
  },
  methods: {
    open() {
      window.open('http://gxhh.unzs.com', '_blank')
    },
    getMarketingImgJxuIndex() {
      getMarketingImgJxuIndex({ kindId: this.search.kind }).then((res) => {
        if (res.code == 0) {
          if (res.data && res.data.length > 0) {
            this.imgs = res.data[0]
          } else {
            this.imgs = ''
          }

        }
      })
    },
    showProject(pKind, stage) {
      if (pKind === 1) {
        if (stage === 0) return false
        return true
      }
      return true
    },
    // 轮播左侧内容点击跳转详情
    zhuanYeXqs(kind, zId, schoole, itmse) {
      const routeUrl = this.$router.resolve({
        path: `/seventhPage/typeclass?kind=${kind || ''}&zId=${zId || ''
          }&schoole=${schoole || ''}`
      })
      window.open(routeUrl.href, '_blank')
    },

    // 将tab的下标指定为走马灯的下标
    clickBtn(index) {
      this.$refs.carousel.setActiveItem(index)
      this.active = index // tab切换的下标
    },
    // 监听轮播的改变，实现背景色渐变
    changeItem(e) {
      // this.$refs.gradient.style.background = `url(${this.imgList[e].img})`
      this.active = e
    },
    projectDetail(item) {
      this.$router.push({
        path: `/projectChannelPage`,
        query: {
          id: item.pKind
          // schoolId: 1
        }
      })
    },
    selectPageListToIndexRecommend(item) {
      selectPageListToIndexnew(
        4,
        1,
        undefined,
        item.pKind,
        undefined,
        undefined,
        3, // 知识套餐精品班型
        this.search.areaId == 1 ? undefined : this.search.areaId
      ).then((res) => {

        res.pKind = item.pKind
        this.RecommendClassList.push(res)
        this.$forceUpdate()
      })
    },
    /* 点击进入专属班详情 */
    goDetails(item) {
      const routeUrl = this.$router.resolve({
        path: '/VIP/VIPClassxq',
        query: {
          id: item.id
        }
      })
      window.open(routeUrl.href, '_blank')
    },

    // 项目专业
    loadgetTenantidNavigation() {
      getMYTenantKindZyListnew().then(res => {
        this.collageList = res.data
        this.collageList.filter(async (item) => {
          //  
          await this.selectPageListToIndexRecommend(item)
        })
      })
    
    },
    
    swipersLeftsa(item) {
      this.search.kind = item?.pKind || null
      this.kindIndex = item || null
      this.courseClass = []
      this.RecommendClassList.filter((items) => {
        
        if (items.pKind == item?.pKind) {

          this.courseClass = items.rows || []
        }
      })
      this.getMarketingImgJxuIndex()
    },
    nav(item) {
      window.open(item.skipImg, '_blank')
    },
    toInformationDetails(item) {
      const routeUrl = this.$router.resolve({
        name: '新闻资讯详情',
        params: { id: item.id }
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.bannerImgBox {
  position: relative;
  width: 100%;
  margin: 0 auto;

  .gradient {
    position: absolute;
    top: -20px;
    width: 100%;
    backdrop-filter: blur(200px);
    height: 300px;
    opacity: 0.3;
    // background-color: rgba(116, 116, 116, 0.4);
    margin: auto;
  }

  .MaskLayer {
    position: absolute;
    top: -20px;
    height: 450px;
    z-index: 1;
    background-image: linear-gradient(to top,
        rgba(246, 246, 246, 1),
        rgba(245, 245, 245, 0.6),
        rgba(245, 245, 245, 0.1));
    backdrop-filter: blur(110px);
    width: 100%;
  }

  .bannerImg {
    position: absolute;
    z-index: 2;
    align-self: center;
    position: relative;
    width: 100%;
    margin: 40px auto 0;
    display: flex;
    height: 450px;
    // background-color: #333333;
    justify-content: space-between;
  }
}

/* ===========项目专业============= */
// 缩宽
.wrapBottom {
  cursor: pointer;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin-left: 217px;
  backdrop-filter: blur(20px);
  width: 658px;
  z-index: 99;
  padding: 0px 0px 30px 30px;
  overflow-y: scroll;
  scrollbar-width: none;
  // box-shadow: 0px 2px 4px 2px rgba(153, 153, 153, 0.20000000298023224);
  border-radius: 0px 8px 8px 0px;
}

.wrap {
  cursor: pointer;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin-left: 217px;
  width: 658px;
  background: linear-gradient(to right top,
      rgba(245, 245, 245, 0.7),
      rgba(245, 245, 245, 0.7),
      rgba(245, 245, 245, 0.7));
  z-index: 99999;
  padding: 0px 0px 30px 30px;
  overflow-y: scroll;
  scrollbar-width: none;
  // box-shadow: 0px 2px 4px 2px rgba(153, 153, 153, 0.20000000298023224);
  border-radius: 0px 8px 8px 0px;

  .colleges_title {
    .text {
      width: 64px;
      height: 21px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }

  .contentWrapLeft {
    width: 100%;
    display: flex;
    justify-content: start;

    .contentItem {
      width: 25%;

      .imgClass {
        width: 130px;
        height: 74px;
        border-radius: 4px;
      }

      &:hover {
        color: #00ab8c;
      }

      .item_content {
        width: 125px;
        margin-top: 6px;
        height: 35px;
        font-size: 12px;
        line-height: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
      }
    }
  }

  .title {
    font-size: 16px;
    font-family: Microsoft YaHei-Bold;
    font-weight: bold;
    color: #333333;
    line-height: 16px;
    padding-top: 17px;
    flex-shrink: 0;
  }
}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}

/* ========================== */
.content {
  width: 100%;
  border-radius: 10px !important;

  .swipersLefts {
    position: absolute;
    top: -40px;
    z-index: 1000;
    cursor: pointer;
    flex-shrink: 0;
    // margin-right: 16px;
    width: 100%;

    .big {
      background-color: #fff;

      .big-top {
        line-height: 40px;
        width: 1200px;
        margin: 0 auto;
        position: relative;

        .big-people {
          position: absolute;
          right: 0;
          top: 3px;
          height: 33px;
          width: 97px;
          text-align: center;
          color: #fff;
          background-image: url("../../assets/img/Home/newPeople.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;

          span {
            display: inline-block;
            line-height: 1;
            // margin-top: 13px;
            margin: 16px 0 0 18px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
        }

        .ul {
          display: flex;
          width: 1000px;

          span {
            display: inline-block;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin-right: 40px;
            flex-shrink: 0;
          }

          .on {
            color: #00ab8c !important;
          }
        }
      }
    }

    .onhover:hover {
      color: #00ab8c !important;

    }

    .big-cent {
      width: 1200px;
      height: 450px;
      margin: 0 auto;
      background-color: #fff;
      padding: 10px 30px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

      .big-hot {
        display: flex;
        margin-top: 20px;
        margin-bottom: 12px;
      }

      .colleges_title {
        .text {
          width: 100px;
          height: 21px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          display: flex;

          .el-icon-arrow-right {
            vertical-align: middle;
            margin-top: 3px;
          }
        }
      }

      .contentWrapLeft {
        width: 100%;
        // display: flex;
        overflow: hidden;

        // justify-content: start;
        .contentItem {
          width: 144px;
          float: left;

          .imgClass {
            width: 130px;
            height: 74px;
            border-radius: 4px;
          }

          &:hover {
            color: #00ab8c;
          }

          .item_content {
            width: 125px;
            /**
         * @Autor: lizisheng
         * @Date: 2023-08-11 09:53:49
         * @Description:
         * @method:
         * @return {*}
         */
            margin-top: 6px;
            height: 35px;
            font-size: 12px;
            line-height: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
          }
        }
      }

      .title {
        font-size: 14px;
        font-family: Microsoft YaHei-Bold;
        // font-weight: bold;
        color: #333333;
        line-height: 16px;
        padding-top: 17px;
        display: flex;
        flex-shrink: 0;
      }

      .wraps {
        width: 90%;
        height: auto;
        display: flex;
        font-size: 14px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;

        .wrapss {
          // width: calc(100% / 5);
          width: 120px;
          margin-bottom: 18px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          text-align: left;

          &:hover {
            color: #00ab8c;
            // border-bottom: 1px solid #ff5d51;
          }
        }
      }
    }

    .big-cents {
      width: 850px;
      flex-shrink: 0;
      padding: 0;
      box-shadow: 0;
    }

    .big-centall {
      display: flex;
    }
  }

  // tab按钮
  .distrbute_btns {
    position: absolute;
    display: flex;
    z-index: 1000;
    height: 168px;
    width: 172px;
    background: black;
    top: 46px;
    border-radius: 8px;
    padding: 18px 8px;
    background-color: rgba(78, 78, 78, 0.4);
    right: 39px;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .tabBanner {
      font-size: 14px;
      width: 140px !important;
      height: 30px;
      display: flex;
      align-items: center;
      line-height: 100%;
      color: #cccccc;
      background: none;
      border: none;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    }

    .active {
      color: #ffffff;
      opacity: 1 !important;
    }
  }

  /* 轮播图 */
  /deep/.swImg {
    width: 100%;
    height: 450px;
    opacity: 1;

    .el-image {
      width: 100% !important;
    }

    img {
      // max-width: 100%;
      height: 450px;
      width: 100%;
      margin: auto;
    }

    .el-carousel__item {
      width: 100%;
      height: 100%;
    }

    .el-carousel__arrow {
      width: 32px;
      height: 32px;
      background: rgba(0, 0, 0, 0.2);
    }

    i {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  /deep/.el-carousel__arrow--left {
    position: absolute;
    left: 226px;
  }

  /deep/.el-carousel__arrow--right {
    position: absolute;
    right: 226px;
  }

  .menu-news {
    display: flex;
    margin-top: 17px;
    /* 新闻咨询 */

    .news {
      width: 811px;
      height: 227px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      padding: 24px;
      background: url("~@/assets/img/homeSeventh/newBg.png") no-repeat;
      background-size: 100% 100%;

      .news-content {
        display: flex;
        margin-top: 18px;

        .news-img {
          width: 248px;
          height: 143px;
          margin-right: 22px;
          cursor: pointer;

          &:nth-child(1) {
            margin-right: 24px;
          }
        }

        .news-text {
          padding: 14px 0px 0px;

          .news-item {
            margin-bottom: 20px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5d636d;
            line-height: 14px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:last-child {
              margin-bottom: 0px;
            }

            .icon {
              width: 6px;
              height: 6px;
              background: #dddddd;
              opacity: 1;
              border-radius: 50%;
              flex-shrink: 0;
              margin-right: 8px;
            }

            .title {
              width: 207px;
              flex-shrink: 0;
            }
          }
        }
      }
    }

    /* 金刚区 */
    .menuList {
      width: 257px;
      height: 227px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;

      margin-left: 16px;

      .menu-box {
        border-bottom: 1px solid #edeff4;
        width: 100%;
        height: 50%;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;

        .menu-item {
          width: 50%;
          border-bottom: 1px solid #fff;
          height: 100%;
          padding-top: 28px;

          .menu-img {
            width: 32px;
            height: 32px;
            margin: 0px auto;
          }

          .text {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 14px;
            text-align: center;
            margin-top: 12px;
          }

          &:nth-child(1) {
            border-right: 1px solid #edeff4;
          }
        }
      }
    }
  }
}

.kindname {
  flex-shrink: 0;
}

.big_img {
  width: 276px;
  height: 390px;
  margin-left: 10px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
